import React from "react"

import Layout from "../layouts/Layout"
import Navbar from "../components/Navbar.component"
import Footer from "../components/Footer.component"

const Notfound = () => {
  return (
    <Layout>
      <div>
        <Navbar />
        <div className="not-found">
          <h1>404</h1>
          <p>Oops! You have entered the wrong room in the hall way.</p>
        </div>
        <Footer />
      </div>
    </Layout>
  )
}

export default Notfound
